import axios from 'axios'

class Reuqest {
	constructor(config) {
		this.instance = axios.create(config)

		// 添加所有实例都有的拦截器
		this.instance.interceptors.request.use(
			(config) => {
				return config
			},
			(err) => {
				return err
			}
		)
		this.instance.interceptors.response.use(
			(res) => {
				return res.data
			},
			(err) => {
				return err
			}
		)
	}

	requset(config) {
		return new Promise((resolve, reject) => {
			this.instance
				.request(config)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	get(config) {
		return this.requset({...config, method: 'GET'})
	}

	post(config) {
		return this.requset({...config, method: 'POST'})
	}

	delete(config) {
		return this.requset({...config, method: 'DELETE'})
	}

	patch(config) {
		return this.requset({...config, method: 'PATCH'})
	}

	put(config) {
		return this.requset({...config, method: 'PUT'})
	}
}

export default Reuqest
