import {service} from '../index'

// 导航栏
export function postColumn() {
	return service.post({
		url: '/index/getColumn'
	})
}

// 配置信息
export function postConfig() {
	return service.post({
		url: '/index/config'
	})
}

// 首页数据
export function postHome() {
	return service.post({
		url: '/index/index'
	})
}

// 标题列表
export function postTitleList(data) {
	return service.post({
		url: '/index/titleList',
		data
	})
}

// 内容列表
export function postColumnList(data) {
	return service.post({
		url: '/index/columnList',
		data
	})
}

export function postSearchFor(data) {
	return service.post({
		url: '/index/searchFor',
		data
	})
}

// 意见反馈
export function postFeedbackDo(data) {
	return service.post({
		url: '/index/feedbackDo',
		data
	})
}

// 服务列表
export function postServeList(data) {
	return service.post({
		url: '/index/serveList',
		data
	})
}

// 服务内容数据
export function postServeDetail(data) {
	return service.post({
		url: '/index/serveDetail',
		data
	})
}

// 人员信息标题列表
export function postUserTitleList(data) {
	return service.post({
		url: '/index/userTitleList',
		data
	})
}

// 人员（教练或运动员）列表
export function postUserList(data) {
	return service.post({
		url: '/index/userList',
		data
	})
}

// 视频列表
export function postVideoList(data) {
	return service.post({
		url: '/index/videoList',
		data
	})
}

// 视频详情
export function postVideoDetail(data) {
	return service.post({
		url: '/index/videoDetail',
		data
	})
}

// 相关视频
export function postVideoRecommend() {
	return service.post({
		url: '/index/videoRecommend'
	})
}

// 图片锦集
export function postAlbum(data) {
	return service.post({
		url: '/index/album',
		data
	})
}

// 图片详情
export function postAlbumDetail(data) {
	return service.post({
		url: '/index/albumDetail',
		data
	})
}

// 相关图片
export function postAlbumRecommend() {
	return service.post({
		url: '/index/albumRecommend'
	})
}

// 详情数据
export function postDetail(data) {
	return service.post({
		url: '/index/detail',
		data
	})
}

export function postTitle(data) {
	return service.post({
		url: '/index/getTitle',
		data
	})
}

// banner
export function getBanner(data) {
	return service.post({
		url: '/index/getBanner',
		data
	})
}
