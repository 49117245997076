import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

const routes = [
	// 首页
	{
		path: '/',
		redirect: '/home',
		component: () => import('@/views/home/index.vue'),
		meta: {
			isPosition: false,
			pathName: '首页',
			navBarIndex: 0
		},
		children: [
			{
				path: '/home',
				component: () => import('@/views/home/home.vue'),
				meta: {
					isPosition: false,
					pathName: '首页',
					keepAlive: true
				}
			},
			{
				path: '/search',
				component: () => import('@/views/home/search.vue'),
				meta: {
					isPosition: false,
					pathName: '搜索',
					keepAlive: false
				}
			},
			// 服务中心
			{
				path: '/service',
				redirect: '/information',
				component: () => import('@/views/home/service.vue'),
				meta: {
					isPosition: true,
					pathName: '服务中心',
					keepAlive: false
				},
				children: [
					{
						name: 'information',
						path: '/information',
						component: () => import('@/views/home/children/service/information.vue'),
						meta: {
							isPosition: true,
							pathName: '资料下载',
							asideNavMenuIndex: 0,
							keepAlive: false
						}
					},
					{
						name: 'opinion',
						path: '/opinion',
						component: () => import('@/views/home/children/service/opinion.vue'),
						meta: {
							isPosition: true,
							pathName: '意见反馈',
							asideNavMenuIndex: 1,
							keepAlive: true
						}
					},
					{
						name: 'signup',
						path: '/signup',
						component: () => import('@/views/home/children/service/signup.vue'),
						meta: {
							isPosition: true,
							pathName: '赛事报名',
							asideNavMenuIndex: 2
						}
					},
					{
						name: 'score',
						path: '/score',
						component: () => import('@/views/home/children/service/score.vue'),
						meta: {
							isPosition: true,
							pathName: '成绩查询',
							asideNavMenuIndex: 3
						}
					}
				]
			},
			// 视频
			{
				path: '/video',
				redirect: '/vwrc',
				component: () => import('@/views/home/video.vue'),
				meta: {
					isPosition: true,
					pathName: '视频'
				},
				children: [
					{
						path: '/vwrc',
						component: () => import('@/views/home/children/video/wrc.vue'),
						meta: {
							isPosition: true,
							pathName: '视频锦集'
						}
					},
					{
						name: 'vdetail',
						path: '/vdetail',
						component: () => import('@/views/home/children/video/detail.vue'),
						meta: {
							isPosition: true,
							pathName: '视频详情'
						}
					}
				]
			},
			// 图片
			{
				path: '/picture',
				redirect: '/pwrc',
				component: () => import('@/views/home/picture.vue'),
				meta: {
					isPosition: true,
					pathName: '图片'
				},
				children: [
					{
						path: '/pwrc',
						component: () => import('@/views/home/children/picture/wrc.vue'),
						meta: {
							isPosition: true,
							pathName: '图片锦集'
						}
					},
					{
						name: 'pdetail',
						path: '/pdetail',
						component: () => import('@/views/home/children/picture/detail.vue'),
						meta: {
							isPosition: true,
							pathName: '图片详情'
						}
					}
				]
			},
			// 人员信息
			{
				path: '/personnel',
				redirect: '/coachANDathletes',
				component: () => import('@/views/home/personnel.vue'),
				meta: {
					isPosition: true,
					pathName: '人员信息'
				},
				children: [
					{
						path: '/coachANDathletes',
						name: 'coachANDathletes',
						component: () => import('@/views/home/children/personnel/coachANDathletes.vue'),
						meta: {
							isPosition: true,
							pathName: '金牌教练员',
							asideNavMenuIndex: 0
						}
					}
				]
			}
		]
	},
	// 公共页面
	{
		path: '/public',
		redirect: '/plist',
		component: () => import('@/views/public/index.vue'),
		meta: {
			isPosition: false,
			pathName: '',
			navBarIndex: null
		},
		children: [
			{
				path: '/plist',
				name: 'plist',
				component: () => import('@/views/public/children/list.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			},
			{
				path: '/pudetail',
				name: 'pudetail',
				component: () => import('@/views/public/children/detail.vue'),
				meta: {
					isPosition: true,
					keepAlive: false
				}
			}
		]
	},
	// 关于协会
	{
		path: '/about',
		redirect: '/arich',
		component: () => import('@/views/about/index.vue'),
		meta: {
			isPosition: false,
			pathName: '关于协会',
			navBarIndex: 1
		},
		children: [
			{
				path: '/arich',
				component: () => import('@/views/about/children/rich.vue'),
				meta: {
					isPosition: true,
					pathName: '协会简介',
					asideNavMenuIndex: 0
				}
			}
		]
	},
	// 通告公告
	{
		path: '/notice',
		redirect: '/nolist',
		component: () => import('@/views/notice/index.vue'),
		meta: {
			isPosition: false,
			pathName: '通告公告',
			navBarIndex: 2
		},
		children: [
			{
				path: '/nolist',
				component: () => import('@/views/notice/children/list.vue'),
				meta: {
					isPosition: true,
					pathName: '最新发布',
					asideNavMenuIndex: 0
				}
			}
		]
	},
	// 新闻资讯
	{
		path: '/news',
		redirect: '/nelist',
		component: () => import('@/views/news/index.vue'),
		meta: {
			isPosition: false,
			pathName: '新闻资讯',
			navBarIndex: 3
		},
		children: [
			{
				path: '/nelist',
				component: () => import('@/views/news/children/list.vue'),
				meta: {
					isPosition: true,
					pathName: '新闻媒体',
					asideNavMenuIndex: 0
				}
			},
			{
				path: '/nedetail',
				name: 'nedetail',
				component: () => import('@/views/news/children/detail.vue'),
				meta: {
					isPosition: true,
					pathName: '新闻媒体详情',
					asideNavMenuIndex: 0
				}
			}
		]
	},
	// 政策规定
	{
		path: '/policy',
		redirect: '/polist',
		component: () => import('@/views/policy/index.vue'),
		meta: {
			isPosition: false,
			pathName: '政策规定',
			navBarIndex: 4
		},
		children: [
			{
				name: 'polist',
				path: '/polist',
				component: () => import('@/views/policy/children/list.vue'),
				meta: {
					isPosition: true,
					pathName: '最新政策',
					asideNavMenuIndex: 0
				}
			},
			{
				path: '/podetail',
				name: 'podetail',
				component: () => import('@/views/policy/children/detail.vue'),
				meta: {
					isPosition: true,
					pathName: '最新政策详情',
					asideNavMenuIndex: 0
				}
			}
		]
	}
]

const scrollBehavior = function (to, from, savedPosition) {
	if (savedPosition) {
		return savedPosition
	} else {
		return {x: 0, y: 0}
	}
}

const router = new VueRouter({
	routes,
	scrollBehavior,
	// mode: 'history',
	base: process.env.BASE_URL
})

export default router
