import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
	Button,
	Image,
	Carousel,
	Message,
	CarouselItem,
	Pagination,
	Dropdown,
	DropdownItem,
	DropdownMenu
} from 'element-ui'

const components = [Button, Image, Carousel, Message, CarouselItem, Pagination, Dropdown, DropdownItem, DropdownMenu]

export function registerElement() {
	for (const component of components) {
		Vue.component(component.name, component)
	}
}

Vue.prototype.$message = Message
